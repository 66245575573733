.bottom-bar-wrapper {
    display: flex;
    justify-content: center;
}

.facebook-button {
    background-color: #1976d2 !important;
    color: white !important;
    margin: 10px !important;
}

.facebook-button:hover {
    opacity: 0.5;
}

.instagram { 
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    color: white !important;
    margin: 10px !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.instagram:hover {
    opacity: 0.5;
}