.registration-form-wrapper {
    width: 100%;
    height: 100vh
}


.dues-table-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dues-table-wrapper {
    width: 100%
}

th, td {
    text-align: left;
    padding: 16px;
}