.home-wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--sv-red);
    font-size: x-large;
    font-weight: bold;
    margin: 20px 0;
}