.title-row {
    background-color: var(--sv-red);
    font-size: xx-large;
    display: flex;
    justify-content: center;
    padding: 10px
}

.navigation-row {
    background-color: var(--sv-black);
}