.background {
    background-image: url('/public/images/ball-on-pitch.jpg');
    height: 100px;
    background-size: 100%;
    background-position: bottom;
}

.background-text {
    color: var(--sv-red);
    font-size: xx-large;
    font-weight: bolder;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    filter: none;
    -webkit-filter: none;
}