.wrapper {
    display: flex;
    flex-direction: row;
}

.text-column {
    flex: 3;
}

.picture-column {
    flex: 2;
}

.picture-column img {
    margin: 5px auto;
}

.main-title {
    color: var(--sv-red);
    font-size:xx-large;
    font-weight: bolder;
}

.title {
    color: var(--sv-red);
    font-size: x-large;
    font-weight: bold;
    margin: 20px 0;
}