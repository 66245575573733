:root {
  --sv-red: #C42B38;
  --sv-black: #2E2926;
  --sc-blue: #0032A0
}

.main-content {
  display: flex;
  flex-direction: row;
  margin: 1px 0 0 0;
  min-height: 90vh;
}

.side-bar {
  background-color: var(--sv-black);
  background-image: url('/public/images/sv-logo-digital.png');
  background-size:  contain;
  background-repeat: no-repeat;
  background-position: top 100px right 0px;
  flex: 1;
}

.page {
  flex: 2;
  padding: 10px;
}